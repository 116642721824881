<template>
  <v-navigation-drawer
    bottom
    color="transparent"
    fixed
    height="auto"
    overlay-color="secondary"
    overlay-opacity=".8"
    temporary
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list
      color="white"
      shaped
    >
      <v-list-item
        v-for="(item, i) in items"
        :key="i"
        :exact="item.name === 'Home'"
        color="primary"
        @click="changePage(item.path)"
      >
        <v-list-item-content>
          <v-list-item-title v-text="item.name" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  export default {
    name: 'HomeDrawer',

    props: {
      items: {
        type: Array,
        default: () => ([]),
      },
    },
    methods: {
      changePage (path) {
        let nextRoute = ''
        var currentUrl = window.location.pathname.split('/')
        if (path.length === 2 && currentUrl.length !== 2) {
          nextRoute = `/${path}/${currentUrl[currentUrl.length - 1]}`
        } else if (path.length === 2 && currentUrl.length === 2) {
          nextRoute = `/${path}`
        } else {
          nextRoute = `/${this.$i18n.locale}/${path}`
        }
        return this.$router.replace({ path: nextRoute })
      },
    },
  }
</script>
